import React,{ useState,useEffect } from "react";
import { Helmet } from 'react-helmet-async';
// @mui
import {
    Popover,
    Card,
    Table,
    TableRow,
    TableHead,
    MenuItem,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Button,
    Container, 
    Stack, 
    Typography, 
    Modal, 
    TextField, 
    InputAdornment, 
    InputLabel ,
    FormControl ,
    Select ,
    IconButton, 
    Divider, 
    Snackbar,
    Toolbar,
    OutlinedInput,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { supabaseClient } from 'src/supabase';
import { useNavigate} from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {fDate} from '../utils/formatTime'

//----------------------------------------------------------------------
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
  }));
  
const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: "100%",
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
  
// ----------------------------------------------------------------------


export default function Product (){
    const navigate = useNavigate();
    const [open, setOpen] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [name,setName] = useState('');
    const [companies,setCompanies] = useState([]);
    const [fournisseurs,setFournisseurs] = useState([]);
    const [collections,setCollections] = useState([]);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const [openBar, setOpenBar] = useState(false);
    const [alertMessage,setAlertMessage] = useState('');

    const handleOpenBar = (message)=>{
        setAlertMessage(message)
        setOpenBar(true)
    }

    const handleCloseBar = (event, reason) => {
      setOpenBar(false);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleAdd = async () => {
        await supabaseClient.from('Produits').insert({reference:name,fournisseur:newProdSup,collection:newProdColl})
        const {data} = await supabaseClient.from("Produits").select("*,Fournisseur(name),Stock(*),Collections(*)").order('created_at',{ascending:false})
        console.log("Comp",data)
        setCompanies(data)
        handleClose()
        handleOpenBar(`Un Produit a été ajouté!\nRéference: ${name}.`)
        setName('')
        setNewProdSup('')
        
    };

    useEffect(()=>{
        const fn = async ()=>{
            const resp = await supabaseClient.auth.getSession()
            console.log(resp)
            if (resp.data.session === null){
                navigate("/login",{replace:true})
            }
            const {data:access,error:er} = await supabaseClient.from("Admins").select("*")
            if (access.length === 0 || er){
                navigate('/403',{replace:true})
                console.log("Access",access)
                
            }
            const {data,error} = await supabaseClient.from("Produits").select("*,Fournisseur(name),Stock(*),Collections(*)").order('created_at',{ascending:false})
            console.log("Error",error)
            console.log("First Fetch",data)
            setCompanies(data)
            const {data:fourn,error:err} = await supabaseClient.from("Fournisseur").select("*").order('created_at',{ascending:false})
            setFournisseurs(fourn)
            const {data:coll} = await supabaseClient.from("Collections").select("*").order('created_at',{ascending:false})
            setCollections(coll)
        }
        fn()
    },[])

    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companies.length) : 0;
    const [currentID,setCurrentID] = useState('');
    const [newProdSup,setNewProdSup] = useState('');
    const [newProdColl,setNewProdColl] = useState('');
    const [serachName,setSerachName] = useState('')
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const  handleNewProdSup = (event)=>{
        setNewProdSup(event.target.value)
    }
    const  handleNewProdColl = (event)=>{
        setNewProdColl(event.target.value)
    }
    const handleOpenDelete = () => setOpenModalDelete(true);
    const handleCloseDelete = () => setOpenModalDelete(false);

    const handleFilterByName = (event) => {
        setSerachName(event.target.value)
    };
    const [searchOption,setSearchOption] = useState('reference')
    const handleSearchOption = (event,target)=>{
        setSearchOption(target)
    }

    const handleSearch = async ()=>{
        if(serachName.length > 0){
            console.log(searchOption)
            const {data,error} = await supabaseClient.from("Produits").select("*,Fournisseur(name),Stock(*),Collections(*)").ilike(`${searchOption}`,`%${serachName}%`).order('created_at',{ascending:false})
            console.log('Search',data)
            let dt = []
            if (error === null){
                data.forEach((value)=>{
                    if (value.Fournisseur !== null){
                        dt.push(value)
                    }
                })
            }
            
            console.log('Search Error',error)
            setCompanies(dt)
        }
        setPage(0);
    }


    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleDelete = async ()=>{
        await supabaseClient.from("Produits").delete().eq('id',currentID)
        const {data,error} = await supabaseClient.from("Produits").select("*,Fournisseur(name),Stock(*),Collections(*)").order('created_at',{ascending:false})
        handleCloseMenu()
        handleCloseDelete()
        setCompanies(data)
        console.log({data,error})
        handleOpenBar(`Le Produit a bien été supprimer.`)
    }

    const handleClearSearch = async ()=>{
        const {data,error} = await supabaseClient.from("Produits").select("*,Fournisseur(name),Stock(*),Collections(*)").order('created_at',{ascending:false})
        console.error(error)
        console.log(data)
        setCompanies(data)
    }
    
    return (
        <>
        <Helmet>
            <title> Vos Produits </title>
        </Helmet>
        <Snackbar open={openBar} anchorOrigin={{ vertical:"top", horizontal:'center' }} autoHideDuration={6000} onClose={handleCloseBar} onClick={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                {alertMessage}
            </Alert>
        </Snackbar>
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={1} spacing={2}>
                    <Typography variant="h6"  alignContent='center'>
                        Nouveau Produit
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <TextField sx={{width:'50%',minWidth:250}}  name="name" label="Réference du Produit" onChange={(e)=>setName(e.target.value)}/>
                    
                    <FormControl variant="filled" sx={{ width:'50%',minWidth:250}}>
                        <InputLabel id="demo-simple-select-filled-label">Fournisseur</InputLabel>
                        <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={newProdSup}
                        onChange={handleNewProdSup}
                        >
                        {fournisseurs.map((value,index)=>{
                            return(<MenuItem value={value.id}>{value.name}</MenuItem>)
                        })}
                        </Select>
                    </FormControl>
                    <FormControl variant="filled" sx={{ width:'50%',minWidth:250}}>
                        <InputLabel id="demo-simple-select-filled-label">Gamme</InputLabel>
                        <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={newProdColl}
                        onChange={handleNewProdColl}
                        >
                        {collections.map((value,index)=>{
                            return(<MenuItem value={value.id}>{value.name}</MenuItem>)
                        })}
                        </Select>
                    </FormControl>

                    
                    <Button 
                        variant="contained" 
                        onClick={handleAdd} 
                        mt={4} 
                        disabled={(name.length === 0) || (newProdSup.length === 0) || (newProdColl.length === 0)}
                    >
                    Ajouter Produit 
                    </Button>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
        <Modal
            open={openModalDelete}
            onClose={handleCloseDelete}
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                    <Typography variant="h6"  alignContent='center' textOverflow='revert'>
                        Voulez Vous Supprimer ce Produit ?
                    </Typography>
                    
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Typography variant="h8" noWrap alignContent='center' color="text.secondary" textAlign='center'>
                    <strong> Veuillez noter que les informations associées seront perdu et irrecuperable !</strong>
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                        <Button
                            variant="contained"
                            startIcon={<DeleteForeverIcon />}
                            onClick={handleDelete}
                        >
                            OUI 
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleCloseDelete}
                        >
                            NON
                        </Button>
                    </Stack>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
                Produits
            </Typography>
            <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                Nouveau Produit
            </Button>
            </Stack>

            <Card>
                <Container 
                    sx={{
                        alignSelf:'center',
                        borderColor:'#103996',
                        borderRadius:"10px",
                        backgroundColor:'#fff',
                        padding:3,
                        justifySelf:'center',
                        display:'grid'
                        }}
                >
                <Stack direction="row" width='100%' alignItems="center" justifyContent="space-evenly" >
                    <ToggleButtonGroup
                        style={{justifySelf:'center',display:'block'}}
                        color="primary"
                        value={searchOption}
                        exclusive
                        onChange={handleSearchOption}
                        aria-label="Search Option"
                        
                        >
                        <ToggleButton value="reference">Par Réference</ToggleButton>
                        <ToggleButton value="Fournisseur.name">Par Fournisseur</ToggleButton>
                    </ToggleButtonGroup>
                    <Button variant="contained" onClick={handleClearSearch} >
                        Effacer la recherche
                    </Button>
                </Stack>
                </Container>
                <StyledRoot>
                
                <Stack direction="row" width='100%' alignItems="center" justifyContent="space-between" >
                    <StyledSearch
                        onChange={handleFilterByName}
                        placeholder="Rechercher Un Produit"
                    />
                    <IconButton variant="contained" onClick={handleSearch} sx={{height:'100%',marginLeft:5}} >
                        <Iconify icon="eva:search-fill" sx={{ width: 50, height: '100%' }} />
                    </IconButton>
                    
                </Stack>
                </StyledRoot>
                
                <Scrollbar>
                    <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align = 'center'>
                                    Produit
                                </TableCell>
                                <TableCell align = 'center'>
                                    Fournisseur
                                </TableCell>
                                <TableCell align = 'center'>
                                    Date d'Ajout
                                </TableCell>
                                <TableCell align = 'center'>
                                    Dépot
                                </TableCell>
                                <TableCell align = 'center'>
                                    Tissus Home
                                </TableCell>
                                <TableCell align = 'center'>
                                    Aïn Sebaâ
                                </TableCell>
                                <TableCell align = 'center'>
                                    Rue D'Agadir
                                </TableCell>
                                <TableCell align = 'center'>
                                    {' '}
                                </TableCell>
                                <TableCell align = 'center'>
                                    {' '}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {companies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const {reference,id,Fournisseur:{name},created_at,Stock ,Collections} = row;
                            let total = 0;
                            let reserved = 0;
                            let reservedAS = 0;
                            let reservedRA = 0;
                            Stock.forEach((value)=>{
                                
                                if (value.owner !== 'depot'){
                                    if(value.owner === "Rue D'Agadir"){
                                        reservedRA = reservedRA + value.quant
                                    }
                                    if (value.owner === 'Aïn Sebaâ'){
                                        reservedAS = reservedAS + value.quant
                                    }else{
                                        reserved = reserved + value.quant
                                    }
                                }else{
                                    total = total + value.quant
                                }
                            })
                            return (
                            <TableRow hover onClick={()=>{console.log("rowClicked",id)}} key={id} tabIndex={-1}  >
                               <TableCell component="th" align="center" scope="row" padding="none">
                                    <Typography variant="subtitle2" noWrap>
                                        {reference}
                                    </Typography>
                                </TableCell>
                                <TableCell hover align="center">{name}</TableCell>
                                <TableCell align="center">{fDate(created_at)}</TableCell>
                                <TableCell align="center">{`${total.toFixed(2)}`}</TableCell>
                                <TableCell align="center">{`${reserved.toFixed(2)}`}</TableCell>
                                <TableCell align="center">{`${reservedAS.toFixed(2)}`}</TableCell>
                                <TableCell align="center">{`${reservedRA.toFixed(2)}`}</TableCell>
                                <TableCell align="center">
                                    <IconButton size="large" sx={{ color: 'error.main' }} onClick={()=>{
                                        setCurrentID(id)
                                        handleOpenDelete()}}>
                                        <Iconify icon={'eva:trash-2-outline'} />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton size="large"  color="inherit" onClick={()=>navigate(`/dashboard/stock?id=${id}`)}>
                                        <Iconify icon={'eva:arrow-ios-forward-outline'} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={companies.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>

            
        </Container>
       
        </>
    );



}