import { useState } from 'react';
import { useNavigate ,useSearchParams} from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox,Snackbar,Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { supabaseClient } from 'src/supabase'; 
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [openBar, setOpenBar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const handleClose = (event, reason) => {
    setOpenBar(false);
  };
  const handleClick = () => {
    supabaseClient.auth.signInWithPassword({email:`${email}@benjalloun.ma`,password:password})
    .then(({ error,data })=>{
      if(error === null) {
        if(data.user.user_metadata.type !== 'admin'){
          let pdfID = searchParams.get("id")
          navigate(`/pdf?id=${pdfID}`,{ replace: true })
        }else{
          navigate('/dashboard', { replace: true });
        }
        
      }else{
        setOpenBar(true)
      }
    })
    //console.log('Email :',email,'  Password :',password)
    //navigate('/dashboard', { replace: true });
  };
  return (
    <>
      <Snackbar open={openBar} anchorOrigin={{ vertical:"top", horizontal:'center' }} autoHideDuration={6000} onClose={handleClose} onClick={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                Login OU Mot de passe Erroné !
            </Alert>
        </Snackbar>
      <Stack spacing={3}>
        <TextField name="login" label="Login" onChange={(e)=>setEmail(e.target.value)}/>

        <TextField
          onChange={(e)=>setPassword(e.target.value)}
          name="password"
          label="Mot de Passe"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
