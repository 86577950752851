import React,{ useState,useEffect } from "react";

import { Helmet } from 'react-helmet-async';
// @mui
import {
    Popover,
    Card,
    Table,
    TableRow,
    TableHead,
    MenuItem,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Button,
    Container, 
    Stack, 
    Typography, 
    Autocomplete, 
    TextField, 
    InputAdornment, 
    InputLabel ,
    FormControl ,
    Select ,
    IconButton, 
    Divider, 
    Snackbar,
    Toolbar,
    OutlinedInput,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { supabaseClient } from 'src/supabase';
import { useNavigate} from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {fDate} from '../utils/formatTime'

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

//----------------------------------------------------------------------

pdfMake.vfs = pdfFonts.pdfMake.vfs;

//----------------------------------------------------------------------
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
  }));
  
const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: "100%",
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
  
// ----------------------------------------------------------------------

export default function Documents (){
    const navigate = useNavigate();
    const [gammes,setGammes] = useState([])
    const [fournisseurs,setFournisseurs] = useState([])

    useEffect(()=>{
        const fn = async ()=>{
            const resp = await supabaseClient.auth.getSession()
            console.log(resp)
            if (resp.data.session === null){
                navigate("/login",{replace:true})
            }
            const {data} = await supabaseClient.from("Collections").select("*,Produits(id)")
                        .order('created_at',{ascending:false})
            let gam = []
            data.forEach((value)=>{
                let cm = []
                value.Produits.forEach((val)=>{
                cm.push(val.id)
                })
                gam.push({
                    id: value.id,
                    label : value.name,
                    produits : cm,
                })

            })
            setGammes(gam)
            const {data:four} = await supabaseClient.from("Fournisseur").select("*,Produits(id)")
            .order('created_at',{ascending:false})
            let fourn = []
            four.forEach((value)=>{
                let cm = []
                value.Produits.forEach((val)=>{
                cm.push(val.id)
                })
                fourn.push({
                    id: value.id,
                    label : value.name,
                    produits : cm,
                })

            })
            setFournisseurs(fourn)
        }
        fn()
    },[])

    const handleAutoCompleteGamme = async (event)=>{
        if(event.target.value){
            if(event.target.value.length > 2 ){
                const {data} = await supabaseClient.from("Collections").select("*,Produits(id)").ilike("name",`%${event.target.value}%`)
                .order('created_at',{ascending:false})
                let gam = []
                data.forEach((value)=>{
                    let cm = []
                    value.Produits.forEach((val)=>{
                    cm.push(val.id)
                    })
                    gam.push({
                        id: value.id,
                        label : value.name,
                        produits : cm,
                    })

                })
                setGammes(gam)
                
            }
        }

    }


    const handleAutoComplete = async (event)=>{
        if(event.target.value){
            if(event.target.value.length > 2 ){
                const {data} = await supabaseClient.from("Fournisseur").select("*,Produits(id)").ilike("name",`%${event.target.value}%`)
                .order('created_at',{ascending:false})
                let gam = []
                data.forEach((value)=>{
                    let cm = []
                    value.Produits.forEach((val)=>{
                    cm.push(val.id)
                    })
                    gam.push({
                        id: value.id,
                        label : value.name,
                        produits : cm,
                    })

                })
                setFournisseurs(gam)
                
            }
        }

    }

    

    const fullStockPDF = async ()=>{
        let documentDefinition = {
            pageMargins: [ 40, 60, 40, 60 ],
            content: [
              { text: 'Inventaire Total ', style: 'header',alignement:"center" },
              {
                style: 'tableExample',
                table: {
                    widths: ['*',  '*', '*'],
                    heights: ['*',  '*', '*'],
                  body: [
                    [{text: 'Produits', style: 'tableHeader', alignment: 'center'}, {text: 'Quantité m(s)', style: 'tableHeader', alignment: 'center'},{text: 'Stock', style: 'tableHeader', alignment: 'center'}]
                  ]
                }
              },
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [200, 10, 0, 5],
              },
              subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              tableExample: {
                fontSize: 12,
                margin: [10, 50, 0, 15]
              },
              tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black',
              }
            },
        };
        const {data} = await supabaseClient.from("Produits").select("reference,Stock(quant,owner),Fournisseur(name)").order('created_at',{ascending:false}).gt("Stock.quant",0)
        let tableLines = [...documentDefinition.content[1].table.body]
        data.forEach((value)=>{
            let st = [...value.Stock]
            if(st[0]){
            console.log(st[0]["owner"])  
            }
            
            if(value.Stock){
            let initQuant = ""
            let initOwner = "Dépot"
            if(value.Stock[0]){
                initQuant = value.Stock[0].quant.toString()
                if (value.Stock[0].owner !== "depot"){
                    initOwner = value.Stock[0].owner
                }
            }else{
                initOwner = ""
            }
            if(initQuant != ""){
                tableLines.push([
                    `${value.reference}`,
                    `${initQuant}`,
                    `${initOwner}`,
                    ])
            }
            
            value.Stock.forEach((val,index)=>{
                if(index > 0){
                    let own = (val.owner === "depot")?initOwner:((val.owner === "Tissu Home")?"Tissus Home":val.owner)
                tableLines.push([
                    ``,
                    `${val.quant}`,
                    `${own}`,
                ])
                }
            })
            }         
        })
        documentDefinition.content[1].table.body = tableLines
        pdfMake.createPdf(documentDefinition).download("Inventaire_Total.pdf")
    }

    const [selectedGamme,setSelectedGamme] = useState(null)
    const [selectedFournisseur,setSelectedFournisseur] = useState(null)

    const stockGammePDF = async(label,produits)=>{
        let documentDefinition = {
            pageMargins: [ 40, 60, 40, 60 ],
            content: [
              { text: `Inventaire ${label}`, style: 'header',alignement:"center" },
              {
                style: 'tableExample',
                table: {
                    widths: ['*',  '*', '*'],
                    
                    heights: ['*',  '*', '*'],
                  body: [
                    [{text: 'Produits', style: 'tableHeader', alignment: 'center'}, {text: 'Quantité m(s)', style: 'tableHeader', alignment: 'center'},{text: 'Stock', style: 'tableHeader', alignment: 'center'}]
                  ]
                }
              },
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [200, 10, 0, 5],
              },
              subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              tableExample: {
                fontSize: 12,
                margin: [10, 50, 0, 15]
              },
              tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black',
              }
            },
        };
            const {data} = await supabaseClient.from("Produits").select("reference,Stock(quant,owner),Fournisseur(name)").in("id",produits).order('created_at',{ascending:false})
            let tableLines = [...documentDefinition.content[1].table.body]
            data.forEach((value)=>{
                let st = [...value.Stock]
                if(st[0]){
                console.log(st[0]["owner"])  
                }
                
                if(value.Stock){
                let initQuant = ""
                let initOwner = "Dépot"
                if(value.Stock[0]){
                    initQuant = value.Stock[0].quant.toString()
                    if (value.Stock[0].owner !== "depot"){
                        initOwner = value.Stock[0].owner
                    }
                }else{
                    initOwner = ""
                }
                if(initQuant != ""){
                    tableLines.push([
                        `${value.reference}`,
                        `${initQuant}`,
                        `${initOwner}`,
                        ])
                }
                
                value.Stock.forEach((val,index)=>{
                    if(index > 0){
                        let own = (val.owner === "depot")?initOwner:((val.owner === "Tissu Home")?"Tissus Home":val.owner)
                    tableLines.push([
                        ``,
                        `${val.quant}`,
                        `${own}`,
                    ])
                    }
                })
                }         
            })
            documentDefinition.content[1].table.body = tableLines
            pdfMake.createPdf(documentDefinition).download(`Inventaire_${label}.pdf`)
        
    }

    const [owner,setOwner] = useState('depot')
    const handleOwnerChange = (event,target)=>{
        setOwner(target)
    }

    const stockLocationPDF = async ()=>{
        let label = (owner === "depot")?"Dépot":owner
        let documentDefinition = {
            pageMargins: [ 40, 60, 40, 60 ],
            content: [
              { text: `Inventaire ${label}`, style: 'header',alignement:"center" },
              {
                style: 'tableExample',
                table: {
                    widths: ['*',  '*'],
                    heights: ['*',  '*'],
                  body: [
                    [{text: 'Produits', style: 'tableHeader', alignment: 'center'}, {text: 'Quantité m(s)', style: 'tableHeader', alignment: 'center'}]
                  ]
                }
              },
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [200, 10, 0, 5],
              },
              subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              tableExample: {
                fontSize: 12,
                margin: [10, 50, 0, 15]
              },
              tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black',
              }
            },
        };
        const {data} = await supabaseClient.from("Produits").select("reference,Stock(quant,owner),Fournisseur(name)").eq("Stock.owner",owner).order('created_at',{ascending:false})
            let tableLines = [...documentDefinition.content[1].table.body]
            data.forEach((value)=>{
                let st = [...value.Stock]
                if(st[0]){
                console.log(st[0]["owner"])  
                }
                
                if(value.Stock){
                let initQuant = ""
                let initOwner = "Dépot"
                if(value.Stock[0]){
                    initQuant = value.Stock[0].quant.toString()
                    if (value.Stock[0].owner !== "depot"){
                        initOwner = value.Stock[0].owner
                    }
                }else{
                    initOwner = ""
                }
                if(initQuant != ""){
                    tableLines.push([
                        `${value.reference}`,
                        `${initQuant}`,
                        ])
                }
                
                value.Stock.forEach((val,index)=>{
                    if(index > 0){
                    tableLines.push([
                        ``,
                        `${val.quant}`,
                    ])
                    }
                })
                }         
            })
            
            documentDefinition.content[1].table.body = tableLines
            pdfMake.createPdf(documentDefinition).download(`Inventaire_${label}.pdf`)
        
    }


    return(
        <>
        <Helmet>
            <title> Vos Documents </title>
        </Helmet>
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
                Documents (PDF)
            </Typography>
            
            </Stack>

            <Card>
                
                <Stack direction="column" width='100%' alignItems="center" justifyContent="space-evenly" spacing={3} mb={3} mt={3}>
                    <Stack direction="row" width='100%' alignItems="center" justifyContent="space-evenly" >
                        <Typography variant="h6" >
                            Inventaire Totale
                        </Typography>
                        <Button variant="contained" onClick={fullStockPDF} >
                            <Iconify icon="eva:download-outline" />
                        </Button>
                        
                    </Stack>
                    <Divider sx={{ borderStyle: 'solid',borderWidth:"2px" ,minWidth:250 , width:"50%"}} />
                    <Stack direction="row" width='100%' alignItems="center" justifyContent="space-evenly" >
                        <Typography variant="h6" >
                            Inventaire Par Gamme
                        </Typography>
                        <Button variant="contained" disabled={selectedGamme === null} onClick={()=>{stockGammePDF(selectedGamme.label,selectedGamme.produits)}} >
                            <Iconify icon="eva:download-outline" />
                        </Button>
                        
                    </Stack>
                    <Autocomplete
                            sx={{width:'50%',minWidth:250}}
                            onChange={(event,value)=>{
                                setSelectedGamme(value)
                            }}
                            options={gammes}
                            onInputChange={handleAutoCompleteGamme}
                            renderInput={(params) => (
                            <TextField
                                
                                {...params}
                                label="Gamme"
                                InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                }}
                            />
                            )}
                        
                    />
                    <Divider sx={{ borderStyle: 'solid',borderWidth:"2px" ,minWidth:250 , width:"50%"}} />
                    <Stack direction="row" width='100%' alignItems="center" justifyContent="space-evenly" >
                        <Typography variant="h6" >
                            Inventaire Par Fournisseur
                        </Typography>
                        <Button variant="contained" disabled={selectedFournisseur === null} onClick={()=>{stockGammePDF(selectedFournisseur.label,selectedFournisseur.produits)}} >
                            <Iconify icon="eva:download-outline" />
                        </Button>
                        
                    </Stack>
                    <Autocomplete
                            sx={{width:'50%',minWidth:250}}
                            onChange={(event,value)=>{
                                setSelectedFournisseur(value)
                            }}
                            options={fournisseurs}
                            onInputChange={handleAutoComplete}
                            renderInput={(params) => (
                            <TextField
                                
                                {...params}
                                label="Fournisseur"
                                InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                }}
                            />
                            )}
                        
                    />
                    <Divider sx={{ borderStyle: 'solid',borderWidth:"2px" ,minWidth:250 , width:"50%"}} />
                    <Stack direction="row" width='100%' alignItems="center" justifyContent="space-evenly" >
                        <Typography variant="h6" >
                            Inventaire Par Addresse
                        </Typography>
                        <Button variant="contained" onClick={stockLocationPDF} >
                            <Iconify icon="eva:download-outline" />
                        </Button>
                        
                    </Stack>
                    <ToggleButtonGroup
                        style={{justifySelf:'center',display:'block'}}
                        color="primary"
                        value={owner}
                        exclusive
                        onChange={handleOwnerChange}
                        aria-label="Search Option"
                        >
                        <ToggleButton value="depot">Dépot</ToggleButton>
                        <ToggleButton value="Tissus Home">Tissus Home</ToggleButton>
                        <ToggleButton value="Aïn Sebaâ">Aïn Sebaâ</ToggleButton>
                    </ToggleButtonGroup>

                </Stack>
                
                
                
            </Card>

            
        </Container>



        </>
    )
}
